import React from "react"
import { Link } from "gatsby"

import "./blog-card.scss"

const BlogCard = ({blog_url, blog_date, blog_title, blog_meta, image, position}) => (
    <>
        <Link to={blog_url} className="blog__item">
            <div className="blog__item_img_container">
                <div className="blog__item_img" style={{ backgroundImage: `url('${image}')`, backgroundPosition: `${position}` }}></div>
            </div>
            <div className="blog__item_content">
                <p className="blog__item_date">{blog_date}</p>
                <h3>{blog_title}</h3>
                <p>{blog_meta}</p>
                <p className="blog__item_rm">Read More</p>
            </div>
        </Link>
    </>
)

export default BlogCard
