import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage/heroImage"
import BlogCard from "../components/BlogCard/BlogCard"
import image from "../assets/images/loading.jpg"
import imagetwo from "../assets/images/wagon.jpg"

const BlogIndexPage = () => (
  <Layout>
    <SEO title="Blog" />
    <HeroImage title="Latest News From CNR" subtitle="Blog" image={image} position="center bottom" padding="150px 0" />
    <main>
      <div className="page-width">
        <h2 style={{textAlign: "center"}}>Latest Posts</h2>
      </div>
      <div className="blog">
        <div className="page-width">
          <div className="blog__grid">
            <BlogCard blog_url="/blog-child" blog_date="16th July" blog_title="Specialist In On Site Welding" image={image} blog_meta="This is a little intro into the blog. Just enough content to get someone interested." position="center center"/>
            <BlogCard blog_url="/blog-child" blog_date="16th July" blog_title="Specialist In On Site Welding" image={imagetwo} blog_meta="This is a little intro into the blog. Just enough content to get someone interested." position="center center"/>
            <BlogCard blog_url="/blog-child" blog_date="16th July" blog_title="Specialist In On Site Welding" image={image} blog_meta="This is a little intro into the blog. Just enough content to get someone interested." position="center center"/>
            <BlogCard blog_url="/blog-child" blog_date="16th July" blog_title="Specialist In On Site Welding" image={imagetwo} blog_meta="This is a little intro into the blog. Just enough content to get someone interested." position="center center"/>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default BlogIndexPage
